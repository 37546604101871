import React from "react";
const selfieIcon = require("../styles/icon-selfie-pr.svg");
const bibIcon = require("../styles/icon-bib-pr.svg");

interface PromoteFiltersProps {
  showSelfieFilter: () => void;
  showBibFilter: () => void;
  hidePromoteFilters: () => void;
  photosCount: number;
  selfieSearchEnabled: boolean;
  bibFilterEnabled: boolean;
}

const PromoteFilters = ({
  showSelfieFilter,
  showBibFilter,
  hidePromoteFilters,
  photosCount,
  selfieSearchEnabled,
  bibFilterEnabled
}: PromoteFiltersProps) => {
  return (
    <div>
      <div
        id="filter-dialog"
        className="modal fade in ns ns-modal geo-modal geo-modal--selfie-pr"
        role="dialog"
      >
        <div className="modal-dialog filter-dialog-content">
          <div className="modal-header">
            <span className="modal-title geo-modal--selfie-title">
              It's easy to find{" "}
              <span className="geo-hl-green">your photos</span> using a filter
            </span>
            <button
              type="button"
              className="close"
              onClick={() => {
                hidePromoteFilters();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="geo-modal__body">
            {selfieSearchEnabled ? (
              <div className="geo-modal--filpr">
                <div className="geo-modal--filleft">
                  <img src={selfieIcon} alt="" />
                </div>
                <div className="geo-modal--filright">
                  <h5 className="geo-modal--filtitle">Selfie Search </h5>
                  <h6 className="geo-modal--fildesc">
                    Upload a selfie to see photos of you only.
                  </h6>
                  <button
                    className="geo-btn geo-btn--ltpink geo-btn--small"
                    onClick={() => {
                      showSelfieFilter();
                    }}
                  >
                    Take a selfie or upload
                  </button>
                </div>
              </div>
            ) : null}

            {bibFilterEnabled ? (
              <div className="geo-modal--filpr">
                <div className="geo-modal--filleft">
                  <img src={bibIcon} alt="" />
                </div>
                <div className="geo-modal--filright">
                  <h5 className="geo-modal--filtitle">Bib Number </h5>
                  <h6 className="geo-modal--fildesc">
                    Add your bib number to see photos of you.
                  </h6>
                  <button
                    className="geo-btn geo-btn--ltgreen geo-btn--small"
                    onClick={() => {
                      showBibFilter();
                    }}
                  >
                    Bib Search
                  </button>
                </div>
              </div>
            ) : null}

            <div className="geo-modal--filfooter">
              <h4>
                Or you can also browse through{" "}
                {photosCount > 0 ? <b>{photosCount} photos</b> : "all photos"}{" "}
                to find yours
              </h4>
              <a
                href="#"
                className="geo-link-green geo-link--underline"
                onClick={() => {
                  hidePromoteFilters();
                }}
              >
                See all Albums
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default PromoteFilters;
