import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  Fragment
} from "react";
import _ from "lodash";
import LightboxImage from "./LightboxImage";
import { EventGalleryContext } from "./index";
import { FindPhotosGalleryContext } from "../MasterImageSearch/components/results/PhotosContainer";
import { Photo } from "./types";
import { addParamToURL, withCartIdParam } from "../../utils/BrowserHelper";
import Telemetry from "../../utils/rudderstackAnalytics";
// @ts-ignore
import ahoy from "ahoy.js";
import ajax from "superagent";
import SaveIcon from "./vectors/SaveIcon";
import TagsSection from "../BrowseEvent/components/albums/TagsSections";
// const LightBoxVideo =
//   require("../BrowseEvent/components/albums/LightBoxVideo").default;
const phIcon = require("./images/camera.svg");
import HideIcon from "./vectors/HideIcon";
import HideCancel from "./vectors/HideCancel";
import LightBoxVideo from "./LightBoxVideo";
import {
  AddToCart,
  RemoveFromCart,
  LightBoxViewCart
} from "./vectors/LightBoxCartIcons";
import ISOToDateTimeFormat from "../../utils/ISOToDateTimeFormat";
import ContrastForegroundUtility from "../../utils/ContrastForegroundUtility";

interface Video {
  videoContent: boolean;
  videoMuxPlaybackId: string;
  videoStartTime: string;
  hidden: boolean;
  id: number;
}

const LightBox = ({
  photos,
  photoIndex,
  toggleLightBox,
  trackTelemetry = false,
  parentName,
  isAuthorizedForDirectDownloads,
  userId,
  primaryColor,
  curationApiCalled,
  setCurationApiCalled,
  setEmailModal,
  handleLikeButtonClick,
  showFavIcon,
  trackGuestFavoriteEmailPrompted,
  canShowGuestEmailModal
}: {
  photos: Photo[];
  photoIndex: number;
  toggleLightBox: (index: number) => void;
  trackTelemetry: boolean;
  parentName?: string;
  isAuthorizedForDirectDownloads: boolean;
  userId?: number;
  primaryColor?: string;
  curationApiCalled: boolean;
  setCurationApiCalled: Function;
  setEmailModal: (val: boolean) => void;
  handleLikeButtonClick: (
    photoId: number,
    userId: number,
    source: string
  ) => void;
  showFavIcon?: boolean;
  trackGuestFavoriteEmailPrompted: (photoId: number, source: string) => void;
  canShowGuestEmailModal: (photo: any) => boolean;
}) => {
  const [photoSaved, setPhotoSaved] = useState(false);
  const getPhoto = photos[photoIndex];
  const [defaultHidden, setDefaultHidden] = useState(getPhoto.hidden);
  const [mediaTags, setMediaTags] = useState<
    Array<{
      name: string;
      tagId: number;
      taggableType: string;
    }>
  >([]);
  const eventGalleryContext = useContext(EventGalleryContext);
  const findPhotosContext = useContext(FindPhotosGalleryContext);
  const cartId = eventGalleryContext.cart
    ? eventGalleryContext.cart.id
    : findPhotosContext.cart
    ? findPhotosContext.cart.id
    : null;
  const isEmbed = eventGalleryContext.isEmbed
    ? eventGalleryContext.isEmbed
    : findPhotosContext.isEmbed;

  const canLikeUserId =
    eventGalleryContext.userId || eventGalleryContext.guestUserId
      ? getPhoto.favorite
      : false;
  const [liked, setLiked] = useState(canLikeUserId);

  const resetLightBoxImage = (index: number) => {
    toggleLightBox(index);
  };

  const canDownloadPhotos = () =>
    eventGalleryContext.isAuthorizedForDirectDownloads ||
    isAuthorizedForDirectDownloads;

  const hasPrevImage = () => {
    return !!photos[photoIndex - 1];
  };
  const hasNextImage = () => {
    return !!photos[photoIndex + 1];
  };
  useEffect(() => {
    if (eventGalleryContext.curationMode) {
      fetch(
        `/api/v1/photos/${getPhoto.id}/is_hidden?user_id=${
          userId || eventGalleryContext.userId
        }`
      )
        .then(response => response.json())
        .then(data => setDefaultHidden(data.is_hidden));
    }
    if (canDownloadPhotos()) {
      fetch(
        `/api/v1/photos/${getPhoto.id}/is_saved?user_id=${
          userId || eventGalleryContext.userId
        }`
      )
        .then(response => response.json())
        .then(data => setPhotoSaved(data.is_saved));
    }
    if (eventGalleryContext.canManageTags) {
      getTags();
    }
    if (eventGalleryContext.userId || eventGalleryContext.guestUserId) {
      fetch(
        `/api/v1/photos/${getPhoto.id}/is_liked?user_id=${
          userId ||
          eventGalleryContext.userId ||
          eventGalleryContext.guestUserId
        }`
      )
        .then(response => response.json())
        .then(data => setLiked(data.is_liked));
    }
  }, [photoIndex]);

  const handleSave = () => {
    ajax
      .post(
        addParamToURL(
          withCartIdParam(
            `/api/v1/photos/${getPhoto.id}/save`,
            cartId,
            isEmbed
          ),
          "user_id",
          userId || eventGalleryContext.userId
        )
      )
      .then(() => {
        setPhotoSaved(true);
        // @ts-ignore
        toastr.success("Photo saved", null, { timeOut: 1000 });
      });
  };

  const handleUnsave = () => {
    ajax
      .delete(
        addParamToURL(
          withCartIdParam(`/api/v1/photos/${getPhoto.id}/unsave`, cartId),
          "user_id",
          userId || eventGalleryContext.userId
        )
      )
      .then(() => {
        setPhotoSaved(false);
        // @ts-ignore
        toastr.success("Photo unsaved!", null, { timeOut: 1000 });
      });
  };

  useEffect(() => {
    if (trackTelemetry)
      ahoy.track("photoView", {
        photo_id: getPhoto.id,
        album_id: getPhoto.album.id,
        event_id: getPhoto.album.eventId
      });

    document
      .getElementsByTagName("body")[0]
      .classList.add("modal-open", "egm-single-ph-open");
    // eventGalleryContext.canManageTags
    //   ? null
    //   : document.body.addEventListener("touchmove", preventDefault, {
    //       passive: false
    //     });

    return () => {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("modal-open", "egm-single-ph-open");
      // eventGalleryContext.canManageTags
      //   ? null
      //   : document.body.removeEventListener("touchmove", preventDefault);
    };
  }, []);

  const preventDefault = (e: any) => e.preventDefault();

  const getTags = () => {
    ajax
      .get(`/api/v1/content_tags/${getPhoto.id}`)
      .then(response => setMediaTags(response.body.appliedTags))
      .catch(error => {
        // @ts-ignore
        toastr.error("There was an error loading tags");
        console.error(error);
      });
  };

  const colorTheme = () =>
    primaryColor
      ? primaryColor
      : eventGalleryContext.pageConfiguration
      ? eventGalleryContext.pageConfiguration.primaryColor
      : "#259FA5";

  const handleCloseLightBox = () => (
    setCurationApiCalled(false),
    toggleLightBox(-1),
    eventGalleryContext.curationMode &&
      curationApiCalled &&
      eventGalleryContext.reloadAlbumPhotos(true)
  );

  const curatePhoto = async () => {
    const hiddenVal = curationApiCalled ? defaultHidden : getPhoto.hidden;
    const action = hiddenVal == true ? "unhide" : "hide";
    try {
      const res = await $.ajax({
        url: `/api/v1/photos/${action}`,
        type: "POST",
        data: {
          photo_ids: [getPhoto.id],
          event_id: getPhoto.album.eventId
        }
      });

      //@ts-ignore
      toastr.success("Successfully updated");
      setDefaultHidden(!hiddenVal);
    } catch (err) {
      //@ts-ignore
      toastr.error("Failed to update");
      console.error(err);
    }
  };

  const handleVideoLike = () => {
    if (eventGalleryContext.guestUserId || eventGalleryContext.userId) {
      handleLikeButtonClick(
        getPhoto.id,
        //@ts-ignore
        eventGalleryContext.userId || eventGalleryContext.guestUserId,
        "lightbox"
      );
      setLiked(!liked);
    } else {
      setEmailModal(true);
      trackGuestFavoriteEmailPrompted(getPhoto.id, "lightbox");
    }
  };

  return (
    <div
      id="filter-dialog"
      className="modal fade in egm ns ns-modal ns-modal-small egm-lightbox"
      role="dialog"
    >
      <div className="modal-dialog filter-dialog-content">
        <div className="geo-img-popup" id="modal-mygallery">
          <div className="close-modal">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => handleCloseLightBox()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-mygallery-ttl wtf">
            <div className="mobile-header visible-xs-block">
              <LightBoxHeader
                photo={getPhoto}
                primaryColor={primaryColor}
                trackTelemetry={trackTelemetry}
                parentName={parentName}
                canShowGuestEmailModal={canShowGuestEmailModal}
              />
            </div>
            <div className="modal-body">
              <div className="dv-imgdetails">
                <span className="align-helper" />
                {photos[photoIndex] && photos[photoIndex].videoContent ? (
                  <div style={{ width: "calc(100vw - 60px)" }}>
                    <LightBoxVideo
                      video={photos[photoIndex] as unknown as Video}
                      onClickFavoriteIcon={handleVideoLike}
                      liked={liked}
                      hidden={defaultHidden}
                      showFavIcon={showFavIcon}
                      hasPrevImage={hasPrevImage()}
                      hasNextImage={hasNextImage()}
                      resetLightBoxImage={resetLightBoxImage}
                      photoIndex={photoIndex}
                    />
                  </div>
                ) : (
                  <LightboxImage
                    photo={getPhoto}
                    hidden={defaultHidden}
                    setEmailModal={setEmailModal}
                    liked={liked}
                    setLiked={setLiked}
                    handleLikeButtonClick={handleLikeButtonClick}
                    showFavIcon={showFavIcon}
                    trackGuestFavoriteEmailPrompted={
                      trackGuestFavoriteEmailPrompted
                    }
                    hasPrevImage={hasPrevImage()}
                    hasNextImage={hasNextImage()}
                    resetLightBoxImage={resetLightBoxImage}
                    photoIndex={photoIndex}
                  />
                )}
              </div>
            </div>
            <div>
              <LightBoxFooter
                photo={getPhoto}
                isAuthorizedForDirectDownloads={isAuthorizedForDirectDownloads}
                primaryColor={primaryColor}
                userId={userId}
                photoSaved={photoSaved}
                handleSave={handleSave}
                handleUnsave={handleUnsave}
                curatePhoto={curatePhoto}
                defaultHidden={defaultHidden}
                setCurationApiCalled={setCurationApiCalled}
              />
            </div>
            {eventGalleryContext.canManageTags && (
              <>
                <TagsSection
                  photoId={[getPhoto.id]}
                  appliedTags={mediaTags}
                  contentTagsAPI={"/api/v1/content_tags"}
                  colorStyling={colorTheme()}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const LightBoxHeader = ({
  photo,
  primaryColor,
  trackTelemetry,
  parentName,
  canShowGuestEmailModal
}: {
  photo: any;
  primaryColor?: string;
  trackTelemetry: boolean;
  parentName?: string;
  canShowGuestEmailModal: (photo: any) => boolean;
}) => {
  const eventGalleryContext = useContext(EventGalleryContext);
  const findPhotosContext = useContext(FindPhotosGalleryContext);
  const event = eventGalleryContext.event
    ? eventGalleryContext.event
    : photo.album.event;
  const freeEvent = () => event && event.price === 0;
  const cartHasPhotos = () =>
    eventGalleryContext.cart && eventGalleryContext.cart.photos != undefined
      ? eventGalleryContext.cart.photos.length > 0
      : findPhotosContext.cart && findPhotosContext.cart.photos != undefined
      ? findPhotosContext.cart.photos.length > 0
      : false;
  const cartId = eventGalleryContext.cart
    ? eventGalleryContext.cart.id
    : findPhotosContext.cart
    ? findPhotosContext.cart.id
    : null;
  const isEmbed = eventGalleryContext.isEmbed
    ? eventGalleryContext.isEmbed
    : findPhotosContext.isEmbed;

  const isPrepaid = () => {
    return (
      eventGalleryContext.cart &&
      (eventGalleryContext.arePrepaidPhotosAvailable ||
        _.includes(eventGalleryContext.cart.prepaidPhotos, photo.id))
    );
  };

  const colorTheme = () =>
    primaryColor
      ? primaryColor
      : eventGalleryContext.pageConfiguration
      ? eventGalleryContext.pageConfiguration.primaryColor
      : "#259FA5";

  return (
    <React.Fragment>
      <div className="modal-footer-details egm-lb-footer-cta">
        <div className="geo-img-leftcont">
          {freeEvent() ? (
            <h3 style={{ textTransform: "uppercase" }}>Free</h3>
          ) : isPrepaid() ? (
            <div className="prepaid">
              <span className="prepaid-strike">
                {eventGalleryContext.event.displayPrice}{" "}
              </span>
              <span className="currency">
                {eventGalleryContext.event.currency}
              </span>
              <span className="free"> Free </span>
            </div>
          ) : (
            <h3>
              <strong>{event.displayPrice}</strong>{" "}
              <span>{event.currency}</span>
            </h3>
          )}

          <p>*for High quality digital download</p>
        </div>

        <div className="geo-img-carts">
          {photo.isSelected ? (
            <div
              onClick={e => {
                e.preventDefault();
                if (trackTelemetry) {
                  Telemetry.track("event-gallery:remove-from-cart", {
                    eventId: event.id,
                    eventName: event.name,
                    photoId: photo.id,
                    cartId: cartId,
                    source: "lightbox",
                    embeddedMode: isEmbed
                  });
                }
                eventGalleryContext.cart
                  ? eventGalleryContext.removePhotoFromCart(photo)
                  : findPhotosContext.removePhotoFromCart(photo);
              }}
              className="btn-rem-cart geo-btn--caps"
              style={{
                border: `1px solid ${ContrastForegroundUtility.lbFgColor(
                  colorTheme()
                )}`
              }}
            >
              <RemoveFromCart
                color={ContrastForegroundUtility.lbFgColor(colorTheme())}
              />
              <span
                style={{
                  color: ContrastForegroundUtility.lbFgColor(colorTheme())
                }}
              >
                &nbsp; Remove
              </span>
            </div>
          ) : (
            <div
              onClick={e => {
                e.preventDefault();
                if (trackTelemetry) {
                  Telemetry.track("event-gallery:add-to-cart", {
                    eventId: event.id,
                    eventName: event.name,
                    photoId: photo.id,
                    cartId: cartId,
                    source: "lightbox",
                    embeddedMode: isEmbed
                  });
                }
                if (eventGalleryContext.canShowEmailPopup()) {
                  canShowGuestEmailModal(photo);
                } else if (eventGalleryContext.event) {
                  eventGalleryContext.addPhotoToCart(photo);
                } else {
                  findPhotosContext.addPhotoToCart(photo);
                }
              }}
              className="btn-add-cart geo-btn--caps"
              style={{
                background: ContrastForegroundUtility.lbFgColor(colorTheme())
              }}
            >
              <AddToCart color={"white"} />
              <span
                style={{
                  color: "white"
                }}
              >
                &nbsp; Add to cart
              </span>
            </div>
          )}
          {cartHasPhotos() ? (
            <a
              href={withCartIdParam(
                `/checkout?source=${parentName}`,
                cartId,
                isEmbed
              )}
              target={isEmbed ? "_blank" : ""}
              className="geo-btn--check geo-btn--caps"
              style={{
                background: ContrastForegroundUtility.lightenColor(colorTheme())
              }}
            >
              <LightBoxViewCart
                color={ContrastForegroundUtility.lbFgColor(colorTheme())}
              />
              <span
                style={{
                  color: ContrastForegroundUtility.lbFgColor(colorTheme())
                }}
              >
                &nbsp; View cart
              </span>
            </a>
          ) : null}
        </div>
      </div>
      <div className="modal-footer-actions"></div>
    </React.Fragment>
  );
};

const LightBoxFooter = ({
  photo,
  isAuthorizedForDirectDownloads,
  userId,
  photoSaved,
  handleSave,
  handleUnsave,
  primaryColor,
  defaultHidden,
  setCurationApiCalled,
  curatePhoto
}: {
  photo: Photo;
  isAuthorizedForDirectDownloads: boolean;
  userId?: number;
  photoSaved: boolean;
  handleSave: () => void;
  handleUnsave: () => void;
  primaryColor?: string;
  defaultHidden: boolean;
  setCurationApiCalled: Function;
  curatePhoto: Function;
}) => {
  const eventGalleryContext = useContext(EventGalleryContext);

  const canDownloadPhotos = () =>
    eventGalleryContext.isAuthorizedForDirectDownloads ||
    isAuthorizedForDirectDownloads;

  const toggleSave = () => (photoSaved ? handleUnsave() : handleSave());

  const colorThemeBorder = () =>
    photoSaved
      ? `gray`
      : primaryColor
      ? primaryColor
      : eventGalleryContext.pageConfiguration.primaryColor
      ? eventGalleryContext.pageConfiguration.primaryColor
      : `#259fa5`;

  const colorTheme = () =>
    primaryColor
      ? primaryColor
      : eventGalleryContext.pageConfiguration.primaryColor
      ? eventGalleryContext.pageConfiguration.primaryColor
      : `#259fa5`;

  const hidePhotographersFilter = () =>
    eventGalleryContext.hidePhotographersFilter ||
    photo.hidePhotographersFilter;

  return (
    <div className="dv-imgdetails-foot-wrap">
      <div className="dv-imgdetails-text">
        <div className="geo-ph-details">
          {!hidePhotographersFilter() && photo.album.photographerName && (
            <p className="geo-lt__phname">
              <img src={phIcon} />{" "}
              <a
                href={`mailto:${photo.album.photographerEmail}?cc=support@geosnapshot.com&subject=Customer contact about event: ${photo.album.eventName}`}
              >
                {photo.album.photographerName}{" "}
                <i className="fa fa-angle-right"></i>
              </a>
            </p>
          )}
          {photo.dateTimeTaken && (
            <span className="geo-timeTaken">
              {ISOToDateTimeFormat(photo.dateTimeTaken)}
            </span>
          )}
        </div>

        <div
          className="download-save-btns"
          style={{ justifyContent: "flex-end" }}
        >
          {canDownloadPhotos() ? (
            <Fragment>
              <button
                data-toggle="tooltip"
                data-placement="bottom"
                className="save-btn"
                onClick={() => toggleSave()}
                style={{
                  border: `1px solid ${ContrastForegroundUtility.lbFgColor(
                    colorThemeBorder()
                  )}`
                }}
              >
                <SaveIcon
                  color={
                    photoSaved
                      ? "gray"
                      : ContrastForegroundUtility.lbFgColor(colorTheme())
                  }
                />
              </button>
              <a
                href={`/api/v1/photos/${photo.id}/download`}
                target="_blank"
                className="btn btn-primary btn-download-on-img"
                style={{
                  backgroundColor: ContrastForegroundUtility.lbFgColor(
                    colorTheme()
                  )
                }}
              >
                <span
                  className="fa fa-download"
                  style={{
                    color: "white"
                  }}
                />
              </a>
            </Fragment>
          ) : null}
          {eventGalleryContext.curationMode && (
            <button
              className="geo-icon-btn-styling"
              onClick={() => (curatePhoto(), setCurationApiCalled(true))}
            >
              {defaultHidden ? (
                <HideIcon
                  color={eventGalleryContext.pageConfiguration.primaryColor}
                  width={40}
                />
              ) : (
                <HideCancel
                  color={eventGalleryContext.pageConfiguration.primaryColor}
                  width={40}
                />
              )}
            </button>
          )}
        </div>
      </div>
      <a
        className="geo-report__mbtn"
        target="_blank"
        href={photo.reportPhotoPath}
      >
        <i className="fa fa-flag"></i> <u>Report Content</u>
      </a>
    </div>
  );
};

export default LightBox;
