import React from "react";
import { Photo } from "./types";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import MobilePhotoGallery from "./MobilePhotoGallery";
const gssNewLoader = require("../../assets/gss-loader.gif");

const Photos = ({
  photos,
  hasMore,
  loadPhotos,
  trackTelemetry,
  isAuthorizedForDirectDownloads,
  userId,
  primaryColor,
  likedMedia,
  handleLikeButtonClick,
  reset,
  parentName,
  showFavIcon,
  canShowGuestEmailModal
}: {
  photos: Photo[];
  hasMore: boolean;
  loadPhotos: () => void;
  trackTelemetry: boolean;
  isAuthorizedForDirectDownloads: boolean;
  userId?: number;
  primaryColor?: string;
  likedMedia: Array<{ photoId: number; userId: number }>;
  handleLikeButtonClick: (
    photoId: number,
    userId: number,
    source: string
  ) => void;
  reset?: boolean;
  parentName?: string;
  showFavIcon?: boolean;
  canShowGuestEmailModal: (photo: any) => void;
}) => {
  return (
    <div className="dv-album-wrap">
      <InfiniteScroll
        dataLength={photos.length}
        next={loadPhotos}
        hasMore={hasMore}
        scrollThreshold={0.7}
        loader={
          <div
            className="egm-loader gss-loader dv-section-footer loading"
            style={{ clear: "both" }}
          >
            <img className="photos-date-img" src={gssNewLoader} />
          </div>
        }
      >
        <MobilePhotoGallery
          photos={photos}
          primaryColor={primaryColor}
          trackTelemetry={trackTelemetry}
          isAuthorizedForDirectDownloads={isAuthorizedForDirectDownloads}
          userId={userId}
          likedMedia={likedMedia}
          handleLikeButtonClick={handleLikeButtonClick}
          reset={reset}
          parentName={parentName}
          showFavIcon={showFavIcon}
          canShowGuestEmailModal={canShowGuestEmailModal}
        />
      </InfiniteScroll>
    </div>
  );
};

export default Photos;
