import React, { useContext, Fragment } from "react";
import { removeParam } from "../../../utils/BrowserHelper";
const warnIcon = require("../styles/icon-red-warn.svg");
import { EventGalleryContext } from "../index";

interface PromptClearPrepaidCartProps {
  closePopup: Function;
  clearCart: () => void;
  primaryAuth: string;
}

const PromptClearPrepaidCart = ({
  closePopup,
  clearCart,
  primaryAuth
}: PromptClearPrepaidCartProps) => {
  const removePopupParam = async () => {
    await removeParam("clear-cart-prompt", window.location.href);
    closePopup(false);
  };

  const context = useContext(EventGalleryContext);

  return (
    <div>
      <div
        id="filter-dialog"
        className="modal fade in ns ns-modal geo-modal geo-modal--small geo-modal__remove-offer"
        role="dialog"
      >
        <div className="modal-dialog filter-dialog-content">
          <div className="modal-header">
            &nbsp;
            <button
              type="button"
              className="close"
              onClick={() => {
                removePopupParam();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="geo-modal__header geo-modal--warning-bg">
            <img
              className="geo-modal__warn-icon"
              src={warnIcon}
              alt="exclamation-warn"
            />
            <h4>Clear your cart to redeem the pack</h4>
          </div>
          <div className="geo-modal__body">
            {primaryAuth === "cookie" ? (
              <p>
                The pack can be applied only to this event, clear your cart to
                continue
              </p>
            ) : (
              <p>
                This offer applies to only one (1) selfie per event. Please
                clear the cart in order to upload a selfie.
              </p>
            )}
          </div>
          <div className="modal-footer geo-modal__actions dont-break">
            <a
              className="geo-btn geo-btn--link  geo-btn--caps"
              style={{
                backgroundColor: "#C1C1C1",
                color: "white",
                marginRight: 10
              }}
              onClick={() => {
                removePopupParam();
              }}
            >
              Cancel
            </a>
            <a
              className="geo-btn geo-btn--secondary geo-btn--caps"
              onClick={async () => {
                await clearCart();
                removePopupParam();
              }}
            >
              Clear Cart
            </a>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default PromptClearPrepaidCart;
